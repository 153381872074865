import { ProductProps } from './types';

/**
 * @description Createorder/save
 */
export type CheckoutType = {
  d: string; // from user
  username?: string;
  sessionToken?: string;
  idpartner?: string;
  idordine_finale?: string;
  idutente_finale?: string; 
  email: string;
  nome: string;
  cognome: string;
  indirizzo: string;
  cap: string;
  provincia: string;
  citta: string;
  telefono: string;
  prodotto?: ProductProps;
  note?: string;
  tag?: string;
  privacy: number;
  marketing: number;
  receipt: ReceiptType | null;
  error?: {
    status: string;
    message: string;
  }
};

export type ReceiptType = {
  status: string;
  result: number;
}

export const intialCheckoutState: CheckoutType = {
  d: '',
  username: '',
  sessionToken: '',
  idpartner: '',
  idordine_finale: '',
  idutente_finale: '',
  email: '',
  nome: '',
  cognome: '',
  indirizzo: '',
  cap: '',
  provincia: '',
  citta: '',
  telefono: '',
  prodotto: undefined,
  note: '',
  tag: '',
  privacy: 0,
  marketing: 0,
  receipt: null
};


export type TBuy = {
  buy: {
    buyCredit51c3: boolean;
    buyCredit100: boolean;
  };
  credit51c3: number;
  credit100: number;
  creditExtra51c3: number;
  fringebenefit: number;
  fascia: number;
};