import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../../App";
import { CatalogPage } from "../../pages/catalogpage";
import { CheckoutPage } from "../../pages/checkoutpage";
import { NotFoundPage } from "../../pages/notfoundPage";
import { ThankyouPage } from "../../pages/thankyoupage";

export const MyRoutes = (props: any) => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="catalog" element={<CatalogPage />} />
        <Route path="checkout" element={<CheckoutPage />} />
        <Route path="thankyou" element={<ThankyouPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
