import { CheckoutType, intialCheckoutState } from '../../app/types/checkoutType';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Stato iniziale del token
export const initialStateCheckout: CheckoutType = intialCheckoutState;

// Slice con action e reducers.
const checkoutSlice = createSlice({
  name: "updateProductCheckout",
  initialState: initialStateCheckout,
  reducers: {
    updateProductCheckout(state, action: PayloadAction<CheckoutType>) {
      return  action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateProductCheckout } = checkoutSlice.actions;

export default checkoutSlice.reducer;
