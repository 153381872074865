import { Button, Input, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TypeCallbackSearch, TypeCategory, TypeQuerySearch } from "../../../app/index";
import { useValidateSession } from "../../../hooks";
import AutocompleteCategories from "./autocompleteCategories";
import RangeBar  from "./rangeBar";

interface FiltersProps {
  callbackSearch: TypeCallbackSearch;
  querySearch: TypeQuerySearch;
  excludeCategories: string;
}

export function SearchBar(props: FiltersProps) {
  const { user } = useValidateSession(null);
  const [selectedCategories, setselectedCategories] = useState<TypeCategory[]>(
    props.querySearch.categories
  );
  const [keywordsSearch, setKeywordsSearch] = useState(
    props.querySearch.keyword
  );
  const [rangeValue, setRangeValue] = useState(props.querySearch.rangeValue);

  useEffect(() => {
    setselectedCategories(props.querySearch.categories);
    return () => {};
  }, [props.querySearch.categories]);

  const doSearch = () => {
    props.callbackSearch(selectedCategories, keywordsSearch, rangeValue);
    // setKeywordsSearch("");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeywordsSearch(event.target.value);
  };

  const changeRange = (value:number[]) => {
    setRangeValue(value);
  };
  
  return (
    <SimpleGrid columns={1} spacing={5} fontSize={"md"}>
      <RangeBar rangeValue={rangeValue} setRangeValue={changeRange} walletBuoni={user ? user.credit51c3 : 0}
        walletTotale={ user ? user.credit100 : 0}/>
      <AutocompleteCategories
        currentFilters={selectedCategories}
        setCurrentFilters={setselectedCategories}
        excludeCategories={props.excludeCategories}
      />
      <Input
        width={"15em"}
        value={keywordsSearch}
        placeholder="Cerca per parola chiave"
        onChange={handleChange}
        fontSize={"md"}
      ></Input>

      <Button
        bgColor={"wishlist.600"}
        color={"#ffffff"}
        _hover={{ bg: "wishlist.600" }}
        _active={{
          bg: "wishlist.600",
          transform: "scale(0.98)",
          borderColor: "wishlist.600",
        }}
        onClick={doSearch}
        fontSize={"md"}
      >
        Applica filtri
      </Button>
    </SimpleGrid>
  );
}
