import { combineReducers } from "@reduxjs/toolkit";
import updateCheckout from "../features/checkout/checkoutSlice";
import updateUser from '../features/user/userSlice';

// Root per tutti i reducers.
const rootReducer = combineReducers({
    updateCheckout,
    updateUser
});

export default rootReducer;
